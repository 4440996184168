import React from "react";

import { useShoppingCart, formatCurrencyString } from "use-shopping-cart";
import styled from "styled-components";

import img1 from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";
import img4 from "../assets/4.jpg";

const Container = styled.div`
  hr {
    width: 160px;
    margin-bottom: 65px;
    border: none;
    border-top: 3px dotted lightgray;
  }
`;

const Product = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  :nth-child(1) {
    justify-self: center;
  }
  :nth-child(2) {
    justify-self: start;
  }

  img {
    padding: 5px;
  }
  .thumbnail {
    width: 92px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 60px;
    }
  }

  button {
    border-radius: 50px;
    line-height: 45px;
    padding: 0 35px;
    border: none;
    background-color: rgb(255 230 220);
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-auto-flow: row dense;
  }
`;

const Price = styled.div`
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 60px;
  padding-top: 20px;

  span {
    font-size: 16px;
    font-weight: normal;
  }
`;

const Welcome = styled.div`
  margin: auto;
  width: 615px;
  padding: 50px 0 50px;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const product = {
  name: "Kalenteri 2025",
  description: "LiisArt seinäkalenteri A3",
  sku: "sku_kalenteri_2025",
  price: 2900,
  currency: "EUR",
};
const Home = () => {
  const { addItem, cartDetails, incrementItem } = useShoppingCart();
  const [currentImage, setCurrentImage] = React.useState(0);
  const images = [img1, img2, img3, img4];

  return (
    <Container>
      <Welcome>
        <h1>Tervetuloa LiisArt -verkkokauppaan</h1>
        <p>
          Tervetuloa! Täältä löydät ainutlaatuisia vesiväriprinttejä suoraan
          Jyväskylän sydämestä. Jokainen teos on huolella käsin maalattu ja nyt
          saatavilla printtinä juuri sinulle. Sukella vesivärin maailmaan ja tuo
          kotiisi pala suomalaista taidetta. Tilaa nyt ja anna värien puhua!
        </p>
      </Welcome>
      <hr />
      <Product key={product.sku} className="product">
        <div>
          <img
            src={images[currentImage]}
            alt="Kalenteri"
            style={{ width: "399px", maxWidth: "calc(100vw - 100px)" }}
          />
          <div>
            <img
              className="thumbnail"
              src={img1}
              alt="Kalenteri"
              onClick={() => setCurrentImage(0)}
            />
            <img
              className="thumbnail"
              src={img2}
              alt="Kalenteri"
              onClick={() => setCurrentImage(1)}
            />
            <img
              className="thumbnail"
              src={img3}
              alt="Kalenteri"
              onClick={() => setCurrentImage(2)}
            />
            <img
              className="thumbnail"
              src={img4}
              alt="Kalenteri"
              onClick={() => setCurrentImage(3)}
            />
          </div>
        </div>
        <div>
          <h2 style={{ marginBottom: 0 }}>Kalenteri 2025</h2>
          <p style={{ marginTop: "4px" }}>
            <i>Seinäkalenteri A3</i>
          </p>
          <p>
            Artesaanikalenteri 2025 on LiisArtin uusin painotuote myyntiin{" "}
            <span role="img" aria-label="heart-hands emoji">
              🫶🏼
            </span>
          </p>
          <p>
            Kalenteri on tehty vesiväritaiteen parissa ja jokainen kuva on käsin
            maalattu herkkyydellä ja rakkaudella jokaisen kotia kaunistamaan.
          </p>
          <p>
            Valoa ja väriä vuoteen 2025. Kiitos, kun tuet pienyrittäjää.
            Painettu Tampereella.
          </p>
          <p>Taitto yhteistyössä Eija Liukkosen kanssa.</p>
          <Price>
            {formatCurrencyString({
              value: product.price,
              currency: product.currency,
            })}{" "}
            <span>(sis. kiinnitysklipsi)</span>
          </Price>
          <p>
            <b>Tuotteet lopussa</b>! Mikäli olet kiinnostunut kalenterista, ota
            yhteyttä{" "}
            <a href="mailto:hello.liisart@gmail.com">hello.liisart@gmail.com</a>
          </p>
          <button
            disabled={true}
            onClick={() => {
              if (cartDetails.sku_kalenteri_2025) {
                incrementItem("sku_kalenteri_2025");
              } else {
                addItem(product);
              }
              document.documentElement.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Lisää ostoskoriin
          </button>
        </div>
      </Product>
    </Container>
  );
};

export default Home;
